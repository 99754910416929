<template>
  <div class="site-settings-wrapper">
    <b-notification
      class="notification-wrapper"
      :active.sync="notification.show"
      :type="notification.type"
      auto-close
    >
      {{ notification.text }}
    </b-notification>

    <div class="site-settings-header">
      <b-button class="request-button" type="is-primary" @click="$router.push({ name: 'Sites' })">
        {{ $t('back') }}
      </b-button>
    </div>

    <div class="site-settings-content-wrapper">
      <b-loading
        v-if="loading || loading_industries"
        :is-full-page="false"
        :active="loading || loading_industries"
        :can-cancel="false"
      ></b-loading>

      <div
        v-if="!loading && !loading_industries && settings && settings.lh_vars"
        class="site-setings-content"
      >
        <div class="settings-title">{{ $t('site_settings.title') }}:</div>

        <div class="is-active">
          <b-switch :value="settings.is_active" v-model="settings.is_active" type="is-success">
            {{
              settings && settings.is_active
                ? $t('site_settings.site_on')
                : $t('site_settings.site_off')
            }}
          </b-switch>
        </div>

        <div class="language-wrapper">
          <div class="language-title">
            {{ $t('site_settings.site_language') }}
          </div>

          <b-dropdown aria-role="list">
            <template slot="trigger">
              <div class="select-input">
                <span class="selectet-items">
                  {{ formatLanguage(settings.language) }}
                </span>
              </div>
            </template>
            <b-dropdown-item
              aria-role="listitem"
              @click="selectLanguage(language)"
              v-for="(language, index) in languages"
              :key="index"
            >
              {{ $t(language.title) }}
            </b-dropdown-item>
          </b-dropdown>
        </div>

        <div class="language-wrapper" v-if="$store.getters.get_tariffs_plain">
          <div class="language-title">
            {{ $t('tariff') }}
          </div>

          <b-dropdown aria-role="list">
            <template slot="trigger">
              <div class="select-input">
                <span class="selectet-items" v-if="settings.tariff && settings.tariff.length > 0">
                  {{ getTariffNameById(settings.tariff) }}
                </span>
                <span class="selectet-items" v-else>
                  {{ $t('tariffs.choose_tariff') }}
                </span>
              </div>
            </template>
            <b-dropdown-item
              aria-role="listitem"
              @click="selectTariff(tariff)"
              v-for="(tariff, index) in $store.getters.get_tariffs_plain"
              :key="index"
            >
              {{ tariff.name }}
            </b-dropdown-item>
          </b-dropdown>
        </div>

        <div class="domain-name-wrapper">
          <div class="domain-title">{{ $t('site_settings.site_domain') }}:</div>

          <b-input v-model="settings.domain"></b-input>
        </div>

        <div class="collect-approvement">
          <b-switch
            :value="settings.collect_approvement"
            v-model="settings.collect_approvement"
            type="is-success"
          >
            {{ $t('site_settings.get_user_approvment') }}
          </b-switch>
        </div>

        <div class="collect-approvement">
          <b-switch
            :value="settings.consider_order_statuses_in_statistics"
            v-model="settings.consider_order_statuses_in_statistics"
            type="is-success"
          >
            {{ $t('site_settings.consider_order_statuses_in_statistics') }}
          </b-switch>
        </div>

        <div class="regex-phone-wrapper">
          <div class="regex-phone-title">{{ $t('site_settings.regex_phone') }}:</div>

          <b-input v-model="settings.phone_regex"></b-input>
        </div>

        <div class="regex-cart-wrapper">
          <div class="regex-cart-title">{{ $t('site_settings.regex_cart') }}:</div>

          <b-input v-model="settings.cart_regex"></b-input>
        </div>

        <div class="regex-cart-wrapper">
          <div class="regex-cart-title">{{ $t('site_settings.webhook_url') }}:</div>

          <b-input v-model="settings.webhook_url"></b-input>
        </div>

        <div class="multiple-custom-values-input-wrapper">
          <div class="custom-input-title">
            Отрасли сайта:
          </div>

          <b-input
            v-model="settings.industry"
            custom-class="industry-input"
            @focus="show_industry_autocomplete = true"
            placeholder="Введите название новой отрасли"
          ></b-input>

          <div
            v-if="show_industry_autocomplete && matchNewIndustry.length > 0"
            class="autocomplete-wrapper"
          >
            <div
              class="autocomplete-result-wrapper"
              @click="selectIndustry(industry)"
              v-for="(industry, index) in matchNewIndustry"
              :key="index"
            >
              {{ industry }}
            </div>
          </div>
        </div>

        <div class="collect-approvement">
          <b-switch
            :value="settings.counter_checked"
            v-model="settings.counter_checked"
            type="is-success"
          >
            {{
              settings && settings.counter_checked
                ? $t('site_settings.counter_code_connected')
                : $t('site_settings.counter_code_not_connected')
            }}
          </b-switch>
        </div>

        <div class="collect-approvement">
          <b-switch
            :value="settings.order_code_checked"
            v-model="settings.order_code_checked"
            type="is-success"
          >
            {{
              settings && settings.order_code_checked
                ? $t('site_settings.order_code_connected')
                : $t('site_settings.order_code_not_connected')
            }}
          </b-switch>
        </div>

        <div class="domain-name-wrapper">
          <div class="domain-title">DKIM {{ $t('domain') }}:</div>

          <b-input v-model="settings.mailgun_domain"></b-input>
        </div>

        <div class="settings-title" :title="$t('site_settings.forms_script_hint')">
          {{ $t('site_settings.forms_script') }} *:
        </div>

        <MonacoEditor
          height="600"
          theme="vs-dark"
          language="javascript"
          :value="settings.lh_vars.forms_script"
          @change="changeFormsScript"
          :options="forms_script_editor_options"
        ></MonacoEditor>

        <div class="settings-title">{{ $t('site_settings.user_script') }}:</div>

        <MonacoEditor
          height="600"
          theme="vs-dark"
          language="javascript"
          :value="settings.lh_vars.user_script"
          @change="changeUserScript"
          :options="user_script_editor_options"
        ></MonacoEditor>

        <b-button
          class="request-button save-settings-button"
          type="is-primary"
          @click="checkNeedUpdateRequest"
        >
          {{ $t('save') }}
        </b-button>
      </div>

      <div
        v-if="!loading && !loading_industries"
        class="site-setings-content site-subscription-block"
      >
        <div
          v-if="
            (!loading_subscription && $store.getters.site_subscription) ||
              (createSubscribeFlag && !loading_subscription)
          "
        >
          <div class="settings-title">{{ $t('site_settings.site_subscription') }}:</div>

          <div class="collect-approvement">
            <b-switch
              :value="site_subscription.is_active"
              v-model="site_subscription.is_active"
              type="is-success"
            >
              {{
                site_subscription.is_active
                  ? $t('site_settings.subscription_included')
                  : $t('site_settings.subscription_disabled')
              }}
            </b-switch>
            
          </div>

          <div class="collect-approvement">
            <div class="domain-title">{{ $t('site_settings.mass_emails_number') }}:</div>

            <b-input v-model="site_subscription.limits.mass_emails_count"></b-input>
          </div>

          <div class="collect-approvement">
            <div class="domain-title">{{ $t('site_settings.autocast_emails_count') }}:</div>

            <b-input v-model="site_subscription.limits.autocast_emails_count"></b-input>
          </div>

          <div class="collect-approvement">
            <div class="domain-title">{{ $t('site_settings.day_of_month_limit_renewal') }}:</div>

            <b-input
              v-model="site_subscription.renew_limits_at"
              type="number"
              min="1"
              max="31"
            ></b-input>
          </div>

          <div class="collect-approvement">
            <div class="domain-title">{{ $t('site_settings.paid_till') }}:</div>

            <b-datepicker
              :placeholder="$t('select_date')"
              icon="calendar-today"
              v-model="site_subscription.paid_till"
              :date-formatter="subscriptionDateFormat"
            >
            </b-datepicker>
          </div>

          <div class="collect-approvement expiration-alert-toggle">
            <b-switch
              :value="site_subscription.expiration_alert"
              v-model="site_subscription.expiration_alert"
              type="is-success"
            >
              {{
                site_subscription.expiration_alert
                  ? $t('site_settings.expiration_alert_is_on')
                  : $t('site_settings.expiration_alert_is_off')
              }}
            </b-switch>
          </div>

          <div class="collect-approvement">
            <div class="domain-title">{{ $t('site_settings.days_to_alert') }}:</div>

            <b-input
              v-model="site_subscription.expiration_alert_trigger"
              type="number"
              min="1"
              max="365"
            ></b-input>
          </div>

          <div class="collect-approvement">
            <div class="domain-title">{{ $t('site_settings.alert_emails') }}:</div>

            <b-input v-model="site_subscription.alert_emails"></b-input>
          </div>


          <b-button
            class="request-button"
            type="is-primary"
            @click="checkNeedUpdateSiteSubscription"
          >
            {{ $t('save') }}
          </b-button>
        </div>

        <b-button
          class="request-button"
          type="is-primary"
          v-else-if="!loading_subscription && !$store.getters.site_subscription"
          @click="createSubscribeFlag = true"
        >
          Создать подписку
        </b-button>

        <b-loading
          v-else
          :is-full-page="false"
          :active="loading_subscription"
          :can-cancel="false"
        ></b-loading>
      </div>

      <div
        v-if="!loading && !loading_industries && site_next_vers_features"
        class="site-setings-content"
      >
        <div class="settings-title">
          {{ $t('site_settings.switching_site_to_new_functionality') }}:
        </div>

        <div class="collect-approvement">
          <b-switch
            :value="site_next_vers_features.process_daily_autocasts"
            v-model="site_next_vers_features.process_daily_autocasts"
            type="is-success"
          >
            {{ $t('site_settings.process_daily_autocasts') }}
          </b-switch>
        </div>

        <div class="collect-approvement">
          <b-switch
            :value="site_next_vers_features.process_lead_activity"
            v-model="site_next_vers_features.process_lead_activity"
            type="is-success"
          >
            {{ $t('site_settings.process_lead_activity') }}
          </b-switch>
        </div>

        <div class="collect-approvement">
          <b-switch
            :value="site_next_vers_features.process_lead_page_load"
            v-model="site_next_vers_features.process_lead_page_load"
            type="is-success"
          >
            {{ $t('site_settings.process_lead_page_load') }}
          </b-switch>
        </div>

        <div class="collect-approvement">
          <b-switch
            :value="site_next_vers_features.save_lead_form"
            v-model="site_next_vers_features.save_lead_form"
            type="is-success"
          >
            {{ $t('site_settings.save_lead_form') }}
          </b-switch>
        </div>

        <div class="collect-approvement">
          <b-switch
            :value="site_next_vers_features.widgets"
            v-model="site_next_vers_features.widgets"
            type="is-success"
          >
            {{ $t('site_settings.widgets') }}
          </b-switch>
        </div>

        <div class="collect-approvement">
          <b-switch
            :value="site_next_vers_features.add_to_cart"
            v-model="site_next_vers_features.add_to_cart"
            type="is-success"
          >
            {{ $t('site_settings.add_to_cart') }}
          </b-switch>
        </div>

        <div class="collect-approvement">
          <b-switch
            :value="site_next_vers_features.tick"
            v-model="site_next_vers_features.tick"
            type="is-success"
          >
            {{ $t('site_settings.ticker') }}
          </b-switch>
        </div>

        <div class="collect-approvement">
          <b-switch
            :value="site_next_vers_features.lead_state"
            v-model="site_next_vers_features.lead_state"
            type="is-success"
          >
            {{ $t('site_settings.lead_state_update') }}
          </b-switch>
        </div>

        <div class="collect-approvement">
          <b-switch
            :value="site_next_vers_features.initialize"
            v-model="site_next_vers_features.initialize"
            type="is-success"
          >
            {{ $t('site_settings.lead_initialization') }}
          </b-switch>
        </div>

        <b-button
          class="request-button"
          type="is-primary"
          @click="checkNeedUpdateSiteNextVersFeatures"
        >
          {{ $t('save') }}
        </b-button>
      </div>

      <div v-if="!loading" class="site-setings-content">
        <div class="settings-title">
          Запустить миграцию в новый ЛК:
        </div>

        <b-button class="request-button" type="is-primary" @click="startMigrationHandler">
          Начать миграцию
        </b-button>
      </div>

      <div v-if="!loading && alerts_settings" class="site-setings-content">
        <div class="settings-title">
          Редактирование настроек оповещений для сайта:
        </div>

        <div class="collect-approvement">
          <b-switch
            :value="alerts_settings.send_internal_activity_report"
            @input="updateAlertsSettingsHandler('send_internal_activity_report', $event)"
            type="is-success"
            :disabled="loading_alerts_settings"
          >
            Отчет о внутренней активности сайта
          </b-switch>
        </div>
      </div>

      <div class="yml-wrapper" v-if="!loading && !loading_industries">
        <div v-if="offers_settings" class="site-setings-content">
          <div class="yml-title">
            Настройки офферов
          </div>

          <div class="yml-url-wrapper">
            <div class="yml-url-title">
              URL:
            </div>

            <b-input v-model="offers_settings.url"></b-input>
          </div>

          <div class="yml-url-wrapper">
            <b-switch
              :value="offers_settings.renew_offers"
              v-model="offers_settings.renew_offers"
              type="is-success"
            >
              Обновлять товары из YML файла
            </b-switch>
          </div>

          <div class="yml-significant-params-wrapper">
            <div class="yml-significant-params-title">
              Ключевые параметры в файле:
            </div>

            <div class="significant-params-wrapper">
              <div class="new-significant-param">
                <input
                  type="text"
                  v-model="new_significant_param"
                  placeholder="Введите название нового параметра"
                />
                <img
                  @click="addNewSignificantParam"
                  src="data:image/svg+xml;base64,
                PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iNTEyIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiIgd2lkdGg9IjUxMiIgY2xhc3M9ImhvdmVyZWQtcGF0aHMiPjxnPjxwYXRoIGQ9Im0yNTYgMGMtMTQxLjE2NDA2MiAwLTI1NiAxMTQuODM1OTM4LTI1NiAyNTZzMTE0LjgzNTkzOCAyNTYgMjU2IDI1NiAyNTYtMTE0LjgzNTkzOCAyNTYtMjU2LTExNC44MzU5MzgtMjU2LTI1Ni0yNTZ6bTAgMCIgZmlsbD0iIzIxOTZmMyIgZGF0YS1vcmlnaW5hbD0iIzIxOTZGMyIgY2xhc3M9ImhvdmVyZWQtcGF0aCIgc3R5bGU9ImZpbGw6Izc5NTdENSIgZGF0YS1vbGRfY29sb3I9IiMyMTk2ZjMiPjwvcGF0aD48cGF0aCBkPSJtMzY4IDI3Ny4zMzIwMzFoLTkwLjY2Nzk2OXY5MC42Njc5NjljMCAxMS43NzczNDQtOS41NTQ2ODcgMjEuMzMyMDMxLTIxLjMzMjAzMSAyMS4zMzIwMzFzLTIxLjMzMjAzMS05LjU1NDY4Ny0yMS4zMzIwMzEtMjEuMzMyMDMxdi05MC42Njc5NjloLTkwLjY2Nzk2OWMtMTEuNzc3MzQ0IDAtMjEuMzMyMDMxLTkuNTU0Njg3LTIxLjMzMjAzMS0yMS4zMzIwMzFzOS41NTQ2ODctMjEuMzMyMDMxIDIxLjMzMjAzMS0yMS4zMzIwMzFoOTAuNjY3OTY5di05MC42Njc5NjljMC0xMS43NzczNDQgOS41NTQ2ODctMjEuMzMyMDMxIDIxLjMzMjAzMS0yMS4zMzIwMzFzMjEuMzMyMDMxIDkuNTU0Njg3IDIxLjMzMjAzMSAyMS4zMzIwMzF2OTAuNjY3OTY5aDkwLjY2Nzk2OWMxMS43NzczNDQgMCAyMS4zMzIwMzEgOS41NTQ2ODcgMjEuMzMyMDMxIDIxLjMzMjAzMXMtOS41NTQ2ODcgMjEuMzMyMDMxLTIxLjMzMjAzMSAyMS4zMzIwMzF6bTAgMCIgZmlsbD0iI2ZhZmFmYSIgZGF0YS1vcmlnaW5hbD0iI0ZBRkFGQSIgY2xhc3M9ImFjdGl2ZS1wYXRoIiBzdHlsZT0iZmlsbDojRkFGQUZBIj48L3BhdGg+PC9nPiA8L3N2Zz4="
                  style="width: 20px; height: 20px;"
                />
              </div>

              <div
                v-show="offers_settings.significant_params.length > 0"
                class="exists-significant-params"
              >
                <div
                  class="exists-significant-param"
                  v-for="(param, index) in offers_settings.significant_params"
                  :key="index"
                >
                  <div class="significant-param-name">
                    {{ param }}
                  </div>

                  <img
                    @click="deleteSignificantParam(index)"
                    src="data:image/svg+xml;base64,
                  PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iNTEyIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiIgd2lkdGg9IjUxMiI+PGc+PHBhdGggZD0ibTI1NiAwYy0xNDEuMTY0MDYyIDAtMjU2IDExNC44MzU5MzgtMjU2IDI1NnMxMTQuODM1OTM4IDI1NiAyNTYgMjU2IDI1Ni0xMTQuODM1OTM4IDI1Ni0yNTYtMTE0LjgzNTkzOC0yNTYtMjU2LTI1NnptMCAwIiBmaWxsPSIjZjQ0MzM2IiBkYXRhLW9yaWdpbmFsPSIjRjQ0MzM2Ij48L3BhdGg+PHBhdGggZD0ibTM1MC4yNzM0MzggMzIwLjEwNTQ2OWM4LjMzOTg0MyA4LjM0Mzc1IDguMzM5ODQzIDIxLjgyNDIxOSAwIDMwLjE2Nzk2OS00LjE2MDE1NyA0LjE2MDE1Ni05LjYyMTA5NCA2LjI1LTE1LjA4NTkzOCA2LjI1LTUuNDYwOTM4IDAtMTAuOTIxODc1LTIuMDg5ODQ0LTE1LjA4MjAzMS02LjI1bC02NC4xMDU0NjktNjQuMTA5Mzc2LTY0LjEwNTQ2OSA2NC4xMDkzNzZjLTQuMTYwMTU2IDQuMTYwMTU2LTkuNjIxMDkzIDYuMjUtMTUuMDgyMDMxIDYuMjUtNS40NjQ4NDQgMC0xMC45MjU3ODEtMi4wODk4NDQtMTUuMDg1OTM4LTYuMjUtOC4zMzk4NDMtOC4zNDM3NS04LjMzOTg0My0yMS44MjQyMTkgMC0zMC4xNjc5NjlsNjQuMTA5Mzc2LTY0LjEwNTQ2OS02NC4xMDkzNzYtNjQuMTA1NDY5Yy04LjMzOTg0My04LjM0Mzc1LTguMzM5ODQzLTIxLjgyNDIxOSAwLTMwLjE2Nzk2OSA4LjM0Mzc1LTguMzM5ODQzIDIxLjgyNDIxOS04LjMzOTg0MyAzMC4xNjc5NjkgMGw2NC4xMDU0NjkgNjQuMTA5Mzc2IDY0LjEwNTQ2OS02NC4xMDkzNzZjOC4zNDM3NS04LjMzOTg0MyAyMS44MjQyMTktOC4zMzk4NDMgMzAuMTY3OTY5IDAgOC4zMzk4NDMgOC4zNDM3NSA4LjMzOTg0MyAyMS44MjQyMTkgMCAzMC4xNjc5NjlsLTY0LjEwOTM3NiA2NC4xMDU0Njl6bTAgMCIgZmlsbD0iI2ZhZmFmYSIgZGF0YS1vcmlnaW5hbD0iI0ZBRkFGQSIgY2xhc3M9ImFjdGl2ZS1wYXRoIiBzdHlsZT0iZmlsbDojRkFGQUZBIj48L3BhdGg+PC9nPiA8L3N2Zz4="
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="yml-regex-wrapper">
            <div class="yml-item-title">
              Регулярное выражение для идентификации оффера в фиде:
            </div>

            <b-input v-model="offers_settings.offer_regex"></b-input>
          </div>

          <div class="yml-regex-wrapper">
            <div class="yml-item-title">
              Шаблон названия оффера. Доступны переменные: name, type_prefix, model, vendor
            </div>
              <b-input v-model="offers_settings.offer_name_template" id="offer_name_template_input"></b-input>
            </div>

          <div class="yml-parse-start-hour-wrapper">
            <div class="yml-item-title">
              Час парсинга фидов:
            </div>

            <b-input v-model="offers_settings.parse_start_hour" type="number" min="0" max="23"></b-input>
          </div>

          <div class="yml-picture-dimensions-wrapper">
            <div class="yml-item-title">
              Настройки сжатия изображения:
            </div>

            <div class="yml-picture-dimensions">
              <div class="picture-width">
                <div class="picture-title">
                  Ширина
                </div>

                <b-input v-model="offers_settings.offer_picture_dimensions.width"></b-input>
              </div>

              <div class="picture-height">
                <div class="picture-title">
                  Высота
                </div>

                <b-input v-model="offers_settings.offer_picture_dimensions.height"></b-input>
              </div>
            </div>
          </div>

          <div class="yml-url-wrapper">
            <b-switch
              :value="offers_settings.chunked_parsing"
              v-model="offers_settings.chunked_parsing"
              type="is-success"
            >
              Экспериментальная загрузка офферов
            </b-switch>
          </div>

          <div class="yml-url-wrapper">
            <b-switch
              :value="offers_settings.upload_images"
              v-model="offers_settings.upload_images"
              type="is-success"
            >
              {{ $t('site_settings.upload_images') }}
            </b-switch>
          </div>

          <div class="total-offer-count-block">
            Всего офферов {{ this.$store.getters.yml.total_offers_count }} / Недоступно офферов {{ this.$store.getters.yml.unavailable_offers_count }}
            <b-button 
              class="request-button total-offer-count-block-btn" 
              type="is-primary"
              :disabled="!this.$store.getters.yml.total_offers_count"
              @click="show_delete_all_offers_modal = true">
            {{ $t('site_settings.delete_all_offers') }}
          </b-button>
          <b-button 
              class="request-button total-offer-count-block-btn" 
              type="is-primary"
              :disabled="!this.$store.getters.yml.total_offers_count"
              @click="show_delete_all_goods_images_modal = true">
            {{ $t('site_settings.delete_all_goods_images') }}
          </b-button>
          </div>

          <b-button class="request-button" type="is-primary" @click="checkNeedOffersSettingsUpdate">
            {{ $t('save') }}
          </b-button>
        </div>

        <div v-if="yml" class="right-yml-container">
          <div class="site-setings-content">
            <div class="yml-title">
              Загрузка YML файла
            </div>
            <div v-show="yml && yml.updated_at" class="yml-update-block">
              {{ $t('site_settings.updated_at') }}: {{ formatDate(yml.updated_at) }}
            </div>

            <div
              class="yml-loading-wrapper"
              v-if="
                yml.status === 'uploading' ||
                  yml.images_status === 'uploading' ||
                  yml.subscriptions_status === 'processing'
              "
              :is-full-page="false"
            >
              <div class="yml-loading-title">
                Загрузка товаров
              </div>
              <b-loading
                class="yml-loading"
                :active="
                  yml.status === 'uploading' ||
                    yml.images_status === 'uploading' ||
                    yml.subscriptions_status === 'processing'
                "
                :can-cancel="false"
              ></b-loading>
            </div>

            <div class="control-offers-parse" v-else>
              <div class="upload-subscriptions">
                <b-switch
                  :value="yml_request.subscriptions"
                  v-model="yml_request.subscriptions"
                  type="is-success"
                >
                  {{ $t('site_settings.upload_subscriptions') }}
                </b-switch>
              </div>
            </div>

            <div class="upload-yml-footer">
              <b-button
                :disabled="
                  yml.status === 'uploading' ||
                    yml.images_status === 'uploading' ||
                    yml.subscriptions_status === 'processing'
                "
                class="request-button"
                type="is-primary"
                @click="show_load_yml_modal = true"
              >
                {{ $t('site_settings.parse_offers_button') }}
              </b-button>

              <div class="yml-errors-wrapper" v-show="yml && yml.errors && yml.errors.length > 0">
                <b-button type="is-danger" @click="yml_show_errors = !yml_show_errors">
                  Показать ошибки
                </b-button>
                <div
                  class="yml-errors-block"
                  v-show="yml && yml.errors && yml.errors.length > 0 && yml_show_errors"
                >
                  {{ yml.errors }}
                </div>
              </div>
            </div>
          </div>

          <div class="site-setings-content">
            <div class="yml-title">
              Тестирование Regex
            </div>

            <div class="yml-url-wrapper">
              <div class="yml-url-title">
                URL:
              </div>

              <b-input v-model="test_regular.url"></b-input>
            </div>

            <div class="yml-regex-wrapper">
              <div class="yml-item-title">
                Регулярное выражение:
              </div>

              <b-field
                :type="test_regular.regex_error ? 'is-danger' : ''"
                :message="
                  test_regular.regex_error
                    ? $t('site_settings.validate_regular_test_expression')
                    : ''
                "
              >
                <b-input
                  v-model="test_regular.regex"
                  @input="test_regular.regex_error = false"
                ></b-input>
              </b-field>
            </div>

            <div class="yml-significant-params-wrapper">
              <div class="yml-significant-params-title">
                Ключевые параметры:
              </div>

              <div class="significant-params-wrapper">
                <div class="new-significant-param">
                  <input
                    type="text"
                    v-model="new_test_significant_param"
                    placeholder="Введите название нового параметра"
                  />
                  <img
                    @click="addNewTestSignificantParam"
                    src="data:image/svg+xml;base64,
                  PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iNTEyIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiIgd2lkdGg9IjUxMiIgY2xhc3M9ImhvdmVyZWQtcGF0aHMiPjxnPjxwYXRoIGQ9Im0yNTYgMGMtMTQxLjE2NDA2MiAwLTI1NiAxMTQuODM1OTM4LTI1NiAyNTZzMTE0LjgzNTkzOCAyNTYgMjU2IDI1NiAyNTYtMTE0LjgzNTkzOCAyNTYtMjU2LTExNC44MzU5MzgtMjU2LTI1Ni0yNTZ6bTAgMCIgZmlsbD0iIzIxOTZmMyIgZGF0YS1vcmlnaW5hbD0iIzIxOTZGMyIgY2xhc3M9ImhvdmVyZWQtcGF0aCIgc3R5bGU9ImZpbGw6Izc5NTdENSIgZGF0YS1vbGRfY29sb3I9IiMyMTk2ZjMiPjwvcGF0aD48cGF0aCBkPSJtMzY4IDI3Ny4zMzIwMzFoLTkwLjY2Nzk2OXY5MC42Njc5NjljMCAxMS43NzczNDQtOS41NTQ2ODcgMjEuMzMyMDMxLTIxLjMzMjAzMSAyMS4zMzIwMzFzLTIxLjMzMjAzMS05LjU1NDY4Ny0yMS4zMzIwMzEtMjEuMzMyMDMxdi05MC42Njc5NjloLTkwLjY2Nzk2OWMtMTEuNzc3MzQ0IDAtMjEuMzMyMDMxLTkuNTU0Njg3LTIxLjMzMjAzMS0yMS4zMzIwMzFzOS41NTQ2ODctMjEuMzMyMDMxIDIxLjMzMjAzMS0yMS4zMzIwMzFoOTAuNjY3OTY5di05MC42Njc5NjljMC0xMS43NzczNDQgOS41NTQ2ODctMjEuMzMyMDMxIDIxLjMzMjAzMS0yMS4zMzIwMzFzMjEuMzMyMDMxIDkuNTU0Njg3IDIxLjMzMjAzMSAyMS4zMzIwMzF2OTAuNjY3OTY5aDkwLjY2Nzk2OWMxMS43NzczNDQgMCAyMS4zMzIwMzEgOS41NTQ2ODcgMjEuMzMyMDMxIDIxLjMzMjAzMXMtOS41NTQ2ODcgMjEuMzMyMDMxLTIxLjMzMjAzMSAyMS4zMzIwMzF6bTAgMCIgZmlsbD0iI2ZhZmFmYSIgZGF0YS1vcmlnaW5hbD0iI0ZBRkFGQSIgY2xhc3M9ImFjdGl2ZS1wYXRoIiBzdHlsZT0iZmlsbDojRkFGQUZBIj48L3BhdGg+PC9nPiA8L3N2Zz4="
                    style="width: 20px; height: 20px;"
                  />
                </div>

                <div
                  v-show="test_regular.significant_params.length > 0"
                  class="exists-significant-params"
                >
                  <div
                    class="exists-significant-param"
                    v-for="(param, index) in test_regular.significant_params"
                    :key="index"
                  >
                    <div class="significant-param-name">
                      {{ param }}
                    </div>

                    <img
                      @click="deleteTestSignificantParam(index)"
                      src="data:image/svg+xml;base64,
                    PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iNTEyIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiIgd2lkdGg9IjUxMiI+PGc+PHBhdGggZD0ibTI1NiAwYy0xNDEuMTY0MDYyIDAtMjU2IDExNC44MzU5MzgtMjU2IDI1NnMxMTQuODM1OTM4IDI1NiAyNTYgMjU2IDI1Ni0xMTQuODM1OTM4IDI1Ni0yNTYtMTE0LjgzNTkzOC0yNTYtMjU2LTI1NnptMCAwIiBmaWxsPSIjZjQ0MzM2IiBkYXRhLW9yaWdpbmFsPSIjRjQ0MzM2Ij48L3BhdGg+PHBhdGggZD0ibTM1MC4yNzM0MzggMzIwLjEwNTQ2OWM4LjMzOTg0MyA4LjM0Mzc1IDguMzM5ODQzIDIxLjgyNDIxOSAwIDMwLjE2Nzk2OS00LjE2MDE1NyA0LjE2MDE1Ni05LjYyMTA5NCA2LjI1LTE1LjA4NTkzOCA2LjI1LTUuNDYwOTM4IDAtMTAuOTIxODc1LTIuMDg5ODQ0LTE1LjA4MjAzMS02LjI1bC02NC4xMDU0NjktNjQuMTA5Mzc2LTY0LjEwNTQ2OSA2NC4xMDkzNzZjLTQuMTYwMTU2IDQuMTYwMTU2LTkuNjIxMDkzIDYuMjUtMTUuMDgyMDMxIDYuMjUtNS40NjQ4NDQgMC0xMC45MjU3ODEtMi4wODk4NDQtMTUuMDg1OTM4LTYuMjUtOC4zMzk4NDMtOC4zNDM3NS04LjMzOTg0My0yMS44MjQyMTkgMC0zMC4xNjc5NjlsNjQuMTA5Mzc2LTY0LjEwNTQ2OS02NC4xMDkzNzYtNjQuMTA1NDY5Yy04LjMzOTg0My04LjM0Mzc1LTguMzM5ODQzLTIxLjgyNDIxOSAwLTMwLjE2Nzk2OSA4LjM0Mzc1LTguMzM5ODQzIDIxLjgyNDIxOS04LjMzOTg0MyAzMC4xNjc5NjkgMGw2NC4xMDU0NjkgNjQuMTA5Mzc2IDY0LjEwNTQ2OS02NC4xMDkzNzZjOC4zNDM3NS04LjMzOTg0MyAyMS44MjQyMTktOC4zMzk4NDMgMzAuMTY3OTY5IDAgOC4zMzk4NDMgOC4zNDM3NSA4LjMzOTg0MyAyMS44MjQyMTkgMCAzMC4xNjc5NjlsLTY0LjEwOTM3NiA2NC4xMDU0Njl6bTAgMCIgZmlsbD0iI2ZhZmFmYSIgZGF0YS1vcmlnaW5hbD0iI0ZBRkFGQSIgY2xhc3M9ImFjdGl2ZS1wYXRoIiBzdHlsZT0iZmlsbDojRkFGQUZBIj48L3BhdGg+PC9nPiA8L3N2Zz4="
                    />
                  </div>
                </div>
              </div>
            </div>

            <b-button class="request-button" type="is-primary" @click="testOffersRegular">
              Тест
            </b-button>

            <div v-if="test_regular_result" class="test-regular-result">
              <div class="test-regular-result-title">
                Результат:
              </div>

              <div class="result-item" v-if="test_regular_result.offer_identifier">
                <span>
                  Идентификатор:
                </span>
                <span class="result-value">
                  {{ test_regular_result.offer_identifier }}
                </span>
              </div>

              <div class="result-item" v-if="test_regular_result.offer_hash">
                <span>
                  Хэш:
                </span>
                <span class="result-value">
                  {{ test_regular_result.offer_hash }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="site-setings-content" v-if="!loading && !loading_industries">
        <div class="settings-title">Расчет хэшей страниц сайта:</div>

        <b-button class="request-button" type="is-primary" @click="calculationHashSite">
          {{ $t('site_settings.hash_site_button') }}
        </b-button>
      </div>
    </div>

    <b-modal :active.sync="show_update_modal" :can-cancel="false">
      <div class="modal-card-body">
        <div class="custom-close-modal" @click="show_update_modal = !show_update_modal">
          <svg
            width="16"
            height="16"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.06156 6.00012L11.7806 1.28108C12.0738 0.988138 12.0738 0.512831 11.7806 0.219892C11.4874 -0.0732973 11.0126 -0.0732973 10.7194 0.219892L6.00038 4.93894L1.28108 0.219892C0.987888 -0.0732973 0.513081 -0.0732973 0.219892 0.219892C-0.0732973 0.512831 -0.0732973 0.988138 0.219892 1.28108L4.93919 6.00012L0.219892 10.7192C-0.0732973 11.0121 -0.0732973 11.4874 0.219892 11.7804C0.366487 11.9267 0.558611 12 0.750485 12C0.942359 12 1.13448 11.9267 1.28108 11.7801L6.00038 7.06106L10.7194 11.7801C10.866 11.9267 11.0581 12 11.25 12C11.4419 12 11.634 11.9267 11.7806 11.7801C12.0738 11.4872 12.0738 11.0119 11.7806 10.7189L7.06156 6.00012Z"
              fill="#2C3236"
            />
          </svg>
        </div>

        <div class="modal-title">
          {{ $t('site_settings.update_site_settings') }}
        </div>

        <div class="modal-body">
          {{ $t('site_settings.question_update_settings') }}
        </div>

        <div class="modal-buttons">
          <b-button type="is-danger" @click="show_update_modal = !show_update_modal">
            {{ $t('cancel') }}
          </b-button>

          <b-button type="is-success" @click="updateRequest">
            {{ $t('save') }}
          </b-button>
        </div>
      </div>
    </b-modal>

    <b-modal :active.sync="show_load_yml_modal" :can-cancel="false">
      <div class="modal-card-body">
        <div class="custom-close-modal" @click="show_load_yml_modal = !show_load_yml_modal">
          <svg
            width="16"
            height="16"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.06156 6.00012L11.7806 1.28108C12.0738 0.988138 12.0738 0.512831 11.7806 0.219892C11.4874 -0.0732973 11.0126 -0.0732973 10.7194 0.219892L6.00038 4.93894L1.28108 0.219892C0.987888 -0.0732973 0.513081 -0.0732973 0.219892 0.219892C-0.0732973 0.512831 -0.0732973 0.988138 0.219892 1.28108L4.93919 6.00012L0.219892 10.7192C-0.0732973 11.0121 -0.0732973 11.4874 0.219892 11.7804C0.366487 11.9267 0.558611 12 0.750485 12C0.942359 12 1.13448 11.9267 1.28108 11.7801L6.00038 7.06106L10.7194 11.7801C10.866 11.9267 11.0581 12 11.25 12C11.4419 12 11.634 11.9267 11.7806 11.7801C12.0738 11.4872 12.0738 11.0119 11.7806 10.7189L7.06156 6.00012Z"
              fill="#2C3236"
            />
          </svg>
        </div>

        <div class="modal-title">
          {{ $t('site_settings.upload_yml_file_title') }}
        </div>

        <div class="modal-body">
          {{ $t('site_settings.question_upload_yml') }}
        </div>

        <div class="modal-buttons">
          <b-button type="is-danger" @click="show_load_yml_modal = !show_load_yml_modal">
            {{ $t('cancel') }}
          </b-button>

          <b-button type="is-success" @click="parseSiteOffers">
            {{ $t('download') }}
          </b-button>
        </div>
      </div>
    </b-modal>

    <b-modal :active.sync="show_update_yml_modal" :can-cancel="false">
      <div class="modal-card-body">
        <div class="custom-close-modal" @click="show_update_yml_modal = !show_update_yml_modal">
          <svg
            width="16"
            height="16"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.06156 6.00012L11.7806 1.28108C12.0738 0.988138 12.0738 0.512831 11.7806 0.219892C11.4874 -0.0732973 11.0126 -0.0732973 10.7194 0.219892L6.00038 4.93894L1.28108 0.219892C0.987888 -0.0732973 0.513081 -0.0732973 0.219892 0.219892C-0.0732973 0.512831 -0.0732973 0.988138 0.219892 1.28108L4.93919 6.00012L0.219892 10.7192C-0.0732973 11.0121 -0.0732973 11.4874 0.219892 11.7804C0.366487 11.9267 0.558611 12 0.750485 12C0.942359 12 1.13448 11.9267 1.28108 11.7801L6.00038 7.06106L10.7194 11.7801C10.866 11.9267 11.0581 12 11.25 12C11.4419 12 11.634 11.9267 11.7806 11.7801C12.0738 11.4872 12.0738 11.0119 11.7806 10.7189L7.06156 6.00012Z"
              fill="#2C3236"
            />
          </svg>
        </div>

        <div class="modal-title">
          {{ $t('site_settings.update_offers_settings') }}
        </div>

        <div class="modal-body">
          {{ $t('site_settings.question_update_yml') }}
        </div>

        <div class="modal-buttons">
          <b-button type="is-danger" @click="show_update_yml_modal = !show_update_yml_modal">
            {{ $t('cancel') }}
          </b-button>

          <b-button type="is-success" @click="updateYMLOffersSettings">
            {{ $t('save') }}
          </b-button>
        </div>
      </div>
    </b-modal>

    <b-modal :active.sync="show_update_next_vers_features_modal" :can-cancel="false">
      <div class="modal-card-body">
        <div
          class="custom-close-modal"
          @click="show_update_next_vers_features_modal = !show_update_next_vers_features_modal"
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.06156 6.00012L11.7806 1.28108C12.0738 0.988138 12.0738 0.512831 11.7806 0.219892C11.4874 -0.0732973 11.0126 -0.0732973 10.7194 0.219892L6.00038 4.93894L1.28108 0.219892C0.987888 -0.0732973 0.513081 -0.0732973 0.219892 0.219892C-0.0732973 0.512831 -0.0732973 0.988138 0.219892 1.28108L4.93919 6.00012L0.219892 10.7192C-0.0732973 11.0121 -0.0732973 11.4874 0.219892 11.7804C0.366487 11.9267 0.558611 12 0.750485 12C0.942359 12 1.13448 11.9267 1.28108 11.7801L6.00038 7.06106L10.7194 11.7801C10.866 11.9267 11.0581 12 11.25 12C11.4419 12 11.634 11.9267 11.7806 11.7801C12.0738 11.4872 12.0738 11.0119 11.7806 10.7189L7.06156 6.00012Z"
              fill="#2C3236"
            />
          </svg>
        </div>

        <div class="modal-title">
          {{ $t('site_settings.update_site_settings') }}
        </div>

        <div class="modal-body">
          {{ $t('site_settings.question_update_settings') }}
        </div>

        <div class="modal-buttons">
          <b-button
            type="is-danger"
            @click="show_update_next_vers_features_modal = !show_update_next_vers_features_modal"
          >
            {{ $t('cancel') }}
          </b-button>

          <b-button type="is-success" @click="updateNextVersFeatures">
            {{ $t('save') }}
          </b-button>
        </div>
      </div>
    </b-modal>
    <b-modal :active.sync="show_delete_all_offers_modal" :can-cancel="false">
      <div class="modal-card-body">
        <div
          class="custom-close-modal"
          @click="show_delete_all_offers_modal = !show_delete_all_offers_modal"
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.06156 6.00012L11.7806 1.28108C12.0738 0.988138 12.0738 0.512831 11.7806 0.219892C11.4874 -0.0732973 11.0126 -0.0732973 10.7194 0.219892L6.00038 4.93894L1.28108 0.219892C0.987888 -0.0732973 0.513081 -0.0732973 0.219892 0.219892C-0.0732973 0.512831 -0.0732973 0.988138 0.219892 1.28108L4.93919 6.00012L0.219892 10.7192C-0.0732973 11.0121 -0.0732973 11.4874 0.219892 11.7804C0.366487 11.9267 0.558611 12 0.750485 12C0.942359 12 1.13448 11.9267 1.28108 11.7801L6.00038 7.06106L10.7194 11.7801C10.866 11.9267 11.0581 12 11.25 12C11.4419 12 11.634 11.9267 11.7806 11.7801C12.0738 11.4872 12.0738 11.0119 11.7806 10.7189L7.06156 6.00012Z"
              fill="#2C3236"
            />
          </svg>
        </div>

        <div class="modal-title">
          {{ $t('site_settings.deleting_all_offers') }}
        </div>

        <div class="modal-body">
          {{ $t('site_settings.question_delete_all_offers') }}
        </div>

        <div class="modal-buttons">
          <b-button
            type="is-danger"
            @click="show_delete_all_offers_modal = !show_delete_all_offers_modal"
          >
            {{ $t('cancel') }}
          </b-button>

          <b-button type="is-success" @click="deleteAllOffers">
            {{ $t('delete') }}
          </b-button>
        </div>
      </div>
    </b-modal>
    <b-modal :active.sync="show_delete_all_goods_images_modal" :can-cancel="false">
      <div class="modal-card-body">
        <div
          class="custom-close-modal"
          @click="show_delete_all_goods_images_modal = !show_delete_all_goods_images_modal"
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.06156 6.00012L11.7806 1.28108C12.0738 0.988138 12.0738 0.512831 11.7806 0.219892C11.4874 -0.0732973 11.0126 -0.0732973 10.7194 0.219892L6.00038 4.93894L1.28108 0.219892C0.987888 -0.0732973 0.513081 -0.0732973 0.219892 0.219892C-0.0732973 0.512831 -0.0732973 0.988138 0.219892 1.28108L4.93919 6.00012L0.219892 10.7192C-0.0732973 11.0121 -0.0732973 11.4874 0.219892 11.7804C0.366487 11.9267 0.558611 12 0.750485 12C0.942359 12 1.13448 11.9267 1.28108 11.7801L6.00038 7.06106L10.7194 11.7801C10.866 11.9267 11.0581 12 11.25 12C11.4419 12 11.634 11.9267 11.7806 11.7801C12.0738 11.4872 12.0738 11.0119 11.7806 10.7189L7.06156 6.00012Z"
              fill="#2C3236"
            />
          </svg>
        </div>

        <div class="modal-title">
          {{ $t('site_settings.delete_all_goods_images') }}
        </div>

        <div class="modal-body">
          {{ $t('site_settings.question_delete_all_goods_images') }}
        </div>

        <div class="modal-buttons">
          <b-button
            type="is-danger"
            @click="show_delete_all_goods_images_modal = !show_delete_all_goods_images_modal"
          >
            {{ $t('cancel') }}
          </b-button>

          <b-button type="is-success" @click="deleteAllGoodsImages">
            {{ $t('delete') }}
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import * as moment from 'moment'
import 'moment/locale/ru'
moment.locale('ru')
import MonacoEditor from 'monaco-editor-vue'

import { deepCompareObjects } from '@/utils/index.js'

import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  name: 'SiteSettings',
  components: {
    MonacoEditor
  },
  data() {
    return {
      createSubscribeFlag: false,
      settings: {
        is_active: false
      },
      industries: [],
      show_industry_autocomplete: false,
      loading_industries: false,
      loading: true,
      languages: [
        {
          key: 'en',
          title: 'site_settings.english',
          icon:
            '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.7241 13.2414H0.275874C0.123531 13.2414 0 13.1179 0 12.9655V3.03454C0 2.8822 0.123531 2.75867 0.275874 2.75867H15.7241C15.8764 2.75867 16 2.8822 16 3.03454V12.9655C15.9999 13.1179 15.8764 13.2414 15.7241 13.2414Z" fill="#41479B"/><path d="M15.9999 3.03454C15.9999 2.8822 15.8764 2.75867 15.7241 2.75867H14.4898L9.37924 6.107V2.75867H6.62063V6.107L1.51006 2.75867H0.275874C0.123531 2.75867 0 2.8822 0 3.03454V3.74801L4.38464 6.62071H0V9.37933H4.38464L0 12.2521V12.9655C0 13.1179 0.123531 13.2414 0.275874 13.2414H1.51009L6.62069 9.89308V13.2414H9.37931V9.89308L14.4899 13.2414H15.7241C15.8765 13.2414 16 13.1179 16 12.9655V12.2521L11.6154 9.37936H15.9999V6.62075H11.6153L15.9999 3.74801V3.03454Z" fill="#F5F5F5"/><path d="M15.9999 7.17246H8.82756V2.75867H7.17238V7.17246H0V8.82761H7.17238V13.2414H8.82756V8.82761H15.9999V7.17246Z" fill="#FF4B55"/><path d="M5.5921 9.37927L0.0078125 13.0042C0.0273437 13.1374 0.137187 13.2413 0.275843 13.2413H0.65581L6.60547 9.3793H5.5921V9.37927Z" fill="#FF4B55"/><path d="M10.8247 9.37927H9.81128L15.7523 13.2356C15.8908 13.2209 16.0001 13.1078 16.0001 12.9654V12.7386L10.8247 9.37927Z" fill="#FF4B55"/><path d="M0 3.33902L5.05557 6.62069H6.06894L0.160718 2.78552C0.0663435 2.82937 0 2.92358 0 3.03449V3.33902Z" fill="#FF4B55"/><path d="M10.3926 6.62071L15.9903 2.98707C15.9671 2.85826 15.8596 2.75867 15.7241 2.75867H15.3288L9.37927 6.62071H10.3926Z" fill="#FF4B55"/></svg>'
        },
        {
          key: 'ru',
          title: 'site_settings.russian',
          icon:
            '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16 2.66666H0V13.3334H16V2.66666Z" fill="#0052B4"/><path d="M16 2.66666H0V6.22213H16V2.66666Z" fill="#F0F0F0"/><path d="M16 9.77756H0V13.333H16V9.77756Z" fill="#D80027"/></svg>'
        }
      ],
      show_update_modal: false,
      notification: {
        show: false,
        text: '',
        type: 'is-success'
      },
      yml: null,
      yml_request: {
        site_id: this.$router.currentRoute.params.site_id,
        subscriptions: false
      },
      yml_show_errors: false,
      show_load_yml_modal: false,
      new_significant_param: '',
      offers_settings: null,
      show_update_yml_modal: false,
      test_regular: {
        url: '',
        significant_params: [],
        regex: '',
        regex_error: false
      },
      test_regular_result: null,
      new_test_significant_param: '',
      site_next_vers_features: null,
      show_update_next_vers_features_modal: false,
      show_delete_all_offers_modal: false,
      show_delete_all_goods_images_modal: false,
      user_script_editor_options: {
        autoIndent: true,
        formatOnPaste: true,
        formatOnType: true,
        automaticLayout: true,
        autoClosingBrackets: true
      },
      forms_script_editor_options: {
        autoIndent: true,
        formatOnPaste: true,
        formatOnType: true,
        automaticLayout: true,
        autoClosingBrackets: true
      },
      loading_subscription: true,
      site_subscription: {
        limits: {
          mass_emails_count: '',
          autocast_emails_count: ''
        },
        is_active: false,
        expiration_alert: false,
        paid_till: null,
        renew_limits_at: null,
        expiration_alert_trigger: null,
        alert_emails: '',
        test_alert_emails: /^(\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]{2,4}\s*?,?\s*?)+$/
      }
    }
  },
  async created() {
    const promises = [
      this.getAllTariffs(),
      this.getIndustries(),
      this.getSiteSubscription(this.$route.params.site_id),
      this.getAlertsSettings(this.$route.params.site_id),
      this.siteYMLTimer()
    ]

    let site_settings = this.$store.getters.getSiteById(this.$router.currentRoute.params.site_id)
    if (!site_settings || !site_settings.hasOwnProperty('language')) {
      promises.push(this.siteSettingsRequest())
    } else {
      this.siteSettingsCallback(site_settings)
    }

    await Promise.allSettled(promises)

    const subscription = this.$store.getters.site_subscription

    if (subscription) {
      this.site_subscription.limits.mass_emails_count = subscription.limits.mass_emails_count
      this.site_subscription.limits.autocast_emails_count = subscription.limits.autocast_emails_count
      this.site_subscription.is_active = subscription.is_active
      this.site_subscription.renew_limits_at = subscription.renew_limits_at
      this.site_subscription.paid_till = new Date(subscription.paid_till)
      this.site_subscription.expiration_alert = subscription.expiration_alert
      this.site_subscription.expiration_alert_trigger = subscription.expiration_alert_trigger
      this.site_subscription.alert_emails = subscription.alert_emails.join(', ')
    }

    this.loading_subscription = false
  },
  mounted() {
    document.addEventListener('click', this.closeIndustryAutocomplete)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.closeIndustryAutocomplete)
    this.resetYMLTimer()
    this.$store.commit('setSiteSubscription', null)
  },
  computed: {
    ...mapState({ loading_alerts_settings: state => state.sites.loading_alerts_settings }),
    ...mapGetters(['alerts_settings']),
    matchNewIndustry() {
      if (this.settings.industry && this.settings.industry.length > 0) {
        let result = []
        try {
          let regex = new RegExp(this.settings.industry)
          for (let i = 0; i < this.industries.length; i++) {
            let match = this.industries[i].match(regex)
            if (match && match.length > 0) {
              result.push(this.industries[i])
            }
          }
          return result
        } catch (err) {
          return this.industries
        }
      } else {
        return this.industries
      }
    }
  },
  methods: {
    ...mapActions([
      'getAlertsSettings',
      'updateAlertsSettings',
      'getSiteSubscription',
      'startMigration'
    ]),
    async startMigrationHandler() {
      await this.startMigration(this.$route.params.site_id)
    },
    async updateAlertsSettingsHandler(name, value) {
      await this.updateAlertsSettings({
        site_id: this.$route.params.site_id,
        alerts_settings: { [name]: value }
      })
    },
    formatLanguage(key) {
      let index = this.languages.findIndex(function(language) {
        if (language.key === key) return true
      })
      return index !== -1 ? this.$t(this.languages[index].title) : ''
    },
    selectLanguage(language) {
      this.settings.language = language.key
    },
    siteSettingsRequest() {
      this.$store.dispatch('siteSettings', {
        vm: this,
        callback: this.siteSettingsCallback
      })
    },
    siteSettingsUpdateCallback(settings, reject) {
      if (settings === 'error') {
        let error_text = this.$t('site_forms.update_error_message')
        Array.from(Object.keys(reject.body.errors)).forEach(function(key) {
          if (reject.body.errors[key] && reject.body.errors[key].length > 0) {
            error_text = reject.body.errors[key][0]
          }
        })
        this.loading = false
        this.notification.type = 'is-danger'
        this.notification.show = true
        this.notification.text = error_text
      } else {
        if (this.need_update_industries) {
          this.getIndustries()
        }

        this.notification.type = 'is-success'
        this.notification.show = true
        this.notification.text = this.$t('site_settings.settings_updated_successfully')
        this.siteSettingsCallback(settings)
      }
    },
    siteSettingsCallback(settings) {
      this.settings = JSON.parse(JSON.stringify(settings))
      this.getSiteNextVersFeatures()
    },
    checkNeedUpdateRequest() {
      let settings = this.$store.getters.getSiteById(this.$router.currentRoute.params.site_id)

      if (
        settings.is_active !== this.settings.is_active ||
        settings.language !== this.settings.language ||
        settings.domain !== this.settings.domain ||
        settings.collect_approvement !== this.settings.collect_approvement ||
        settings.phone_regex !== this.settings.phone_regex ||
        settings.cart_regex !== this.settings.cart_regex ||
        settings.industry !== this.settings.industry ||
        settings.counter_checked !== this.settings.counter_checked ||
        settings.order_code_checked !== this.settings.order_code_checked ||
        settings.mailgun_domain !== this.settings.mailgun_domain ||
        settings.lh_vars.user_script !== this.settings.lh_vars.user_script ||
        settings.lh_vars.forms_script !== this.settings.lh_vars.forms_script ||
        settings.tariff !== this.settings.tariff || settings.webhook_url !== this.settings.webhook_url ||
        settings.consider_order_statuses_in_statistics !== this.settings.consider_order_statuses_in_statistics
      ) {
        if (!this.checkCorrectForUpdateIndustry()) {
          this.notification.type = 'is-danger'
          this.notification.show = true
          this.notification.text = 'Название отрасли должно быть не менее 3 символов'
          return
        }

        this.show_update_modal = true
      } else {
        this.notification.type = 'is-success'
        this.notification.show = true
        this.notification.text = this.$t('site_settings.no_changes')
      }
    },
    updateRequest() {
      this.loading = true
      this.show_update_modal = false
      this.$store.dispatch('updateSiteSettings', {
        vm: this,
        callback: this.siteSettingsUpdateCallback
      })

      let settings = this.$store.getters.getSiteById(this.$router.currentRoute.params.site_id)
      if (settings.industry !== this.settings.industry) {
        this.need_update_industries = true
      }
    },

    getAllTariffs() {
      this.$store.dispatch('getAllTariffs', {
        vm: this
      })
    },
    getTariffNameById(id) {
      let index_tariff = this.$store.getters.get_tariffs_plain.findIndex(function(tariff) {
        if (tariff.id === id) return true
        else return false
      })
      return this.$store.getters.get_tariffs_plain[index_tariff].name
    },
    selectTariff(tariff) {
      this.settings.tariff = tariff.id
    },

    getSiteNextVersFeatures() {
      this.$store.dispatch('getSiteNextVersFeatures', {
        vm: this,
        callback: this.getSiteNextVersFeaturesCallback
      })
    },
    getSiteNextVersFeaturesCallback(next_vers_features) {
      this.site_next_vers_features = JSON.parse(JSON.stringify(next_vers_features))
      this.loading = false
    },
    checkNeedUpdateSiteNextVersFeatures() {
      let next_vers_features = this.$store.getters.getSiteById(
        this.$router.currentRoute.params.site_id
      ).next_vers_features
      if (
        next_vers_features.process_daily_autocasts !==
          this.site_next_vers_features.process_daily_autocasts ||
        next_vers_features.process_lead_activity !==
          this.site_next_vers_features.process_lead_activity ||
        next_vers_features.process_lead_page_load !==
          this.site_next_vers_features.process_lead_page_load ||
        next_vers_features.save_lead_form !== this.site_next_vers_features.save_lead_form ||
        next_vers_features.widgets !== this.site_next_vers_features.widgets ||
        next_vers_features.add_to_cart !== this.site_next_vers_features.add_to_cart ||
        next_vers_features.tick !== this.site_next_vers_features.tick ||
        next_vers_features.lead_state !== this.site_next_vers_features.lead_state ||
        next_vers_features.initialize !== this.site_next_vers_features.initialize
      ) {
        this.show_update_next_vers_features_modal = true
      } else {
        this.notification.type = 'is-success'
        this.notification.show = true
        this.notification.text = this.$t('site_settings.no_changes')
      }
    },
    updateNextVersFeatures() {
      this.loading = true
      this.show_update_next_vers_features_modal = false
      this.$store.dispatch('updateNextVersFeatures', {
        vm: this,
        callback: this.updateNextVersFeaturesCallback
      })
    },
    updateNextVersFeaturesCallback(response) {
      this.notification.type = 'is-success'
      this.notification.show = true
      this.notification.text = this.$t('site_settings.settings_updated_successfully')
      this.loading = false
      this.getSiteNextVersFeaturesCallback(response)
    },

    getIndustries() {
      this.loading_industries = true
      this.$store.dispatch('siteIndustries', {
        vm: this,
        callback: this.industriesCallback
      })
    },
    industriesCallback(industries) {
      this.industries = industries
      this.loading_industries = false
      this.need_update_industries = false
    },
    checkCorrectForUpdateIndustry() {
      if (typeof this.settings.industry === 'string' && this.settings.industry.length < 3) {
        return false
      } else {
        return true
      }
    },
    closeIndustryAutocomplete(event) {
      if (
        !event ||
        (event && event.target && !Array.from(event.target.classList).includes('industry-input'))
      ) {
        this.show_industry_autocomplete = false
      }
    },
    selectIndustry(industry) {
      this.settings.industry = industry
      this.closeIndustryAutocomplete()
    },

    formatDate(date) {
      return new Date(date) ? moment(date).format('DD.MM.YYYY HH:mm') : null
    },
    siteYML() {
      this.$store.dispatch('siteYML', {
        vm: this,
        callback: this.siteYMLCallback
      })
    },
    siteYMLCallback(yml) {
      this.yml = {
        updated_at: yml.updated_at,
        status: yml.status,
        images_status: yml.images_status,
        subscriptions_status: yml.subscriptions_status,
        errors: yml.errors,
        parse_start_hour: yml.parse_start_hour,
        offer_name_template: yml.offer_name_template
      }

      if (!this.offers_settings) {
        this.offers_settings = yml
      }
      this.loading = false
    },
    parseSiteOffers() {
      this.show_load_yml_modal = false
      this.$store.dispatch('parseSiteOffers', {
        vm: this,
        callback: this.siteYMLTimer
      })
    },
    resetYMLTimer() {
      clearInterval(this.yml_timer)
      this.yml_timer = null
    },
    siteYMLTimer() {
      this.resetYMLTimer()
      this.siteYML()
      this.yml_timer = setInterval(
        function() {
          if (this.yml) {
            if (
              this.yml.status === 'ok' &&
              this.yml.subscriptions_status === 'ok' &&
              this.yml.images_status === 'ok'
            ) {
              this.resetYMLTimer()
            } else if (
              this.yml.status === 'uploading' ||
              this.yml.images_status === 'uploading' ||
              this.yml.subscriptions_status === 'processing'
            ) {
              this.siteYML()
            } else if (
              this.yml.status === 'error' ||
              this.yml.images_status === 'error' ||
              this.yml.subscriptions_status === 'error'
            ) {
              this.resetYMLTimer()
            } else {
              this.resetYMLTimer()
            }
          }
        }.bind(this),
        5000
      )
    },
    addNewSignificantParam() {
      if (this.new_significant_param.length > 0) {
        this.offers_settings.significant_params.push(this.new_significant_param)
        this.new_significant_param = ''
      }
    },
    deleteSignificantParam(index) {
      this.offers_settings.significant_params.splice(index, 1)
    },
    checkBrackets(str) {
      let strArr = str.split('')
      let flag = 0
      strArr.forEach((el) => {
        if(el === '{') {
          flag++
        } else if (el === '}') {
          flag--
        }
        
      })
      return flag === 0 ? true : false
    },
    checkNeedOffersSettingsUpdate() {
      let old_yml = JSON.parse(JSON.stringify(this.$store.getters.yml))
      if(!this.checkBrackets(this.offers_settings.offer_name_template)) {
        document.getElementById('offer_name_template_input').classList.add('is-danger')

        this.notification.type = 'is-danger'
        this.notification.show = true
        this.notification.text = this.$t('site_settings.offer_name_template_error')
        return
      }
      if (
        old_yml.url != this.offers_settings.url ||
        old_yml.renew_offers != this.offers_settings.renew_offers ||
        !deepCompareObjects(this.offers_settings.significant_params, old_yml.significant_params) ||
        old_yml.offer_regex != this.offers_settings.offer_regex ||
        old_yml.offer_picture_dimensions.height !=
          this.offers_settings.offer_picture_dimensions.height ||
        old_yml.offer_picture_dimensions.width !=
          this.offers_settings.offer_picture_dimensions.width ||
        old_yml.chunked_parsing != this.offers_settings.chunked_parsing ||
        old_yml.upload_images != this.offers_settings.upload_images ||
        old_yml.parse_start_hour != this.offers_settings.parse_start_hour ||
        old_yml.offer_name_template != this.offers_settings.offer_name_template
      ) {
        this.show_update_yml_modal = true
      } else {
        this.notification.type = 'is-success'
        this.notification.show = true
        this.notification.text = this.$t('site_settings.no_changes')
      }
    },
    updateYMLOffersSettings() {
      this.loading = true
      this.show_update_yml_modal = false
      this.$store.dispatch('updateSiteYML', {
        vm: this,
        callback: this.siteYMLUpdateCallback
      })
    },
    async deleteAllOffers() {
      this.show_delete_all_offers_modal = false
      await this.$store.dispatch('deleteAllOffers')
      .then(resp => {
        if(resp === 200) {
          this.loading = true
          this.siteYML()
          this.notification.type = 'is-success'
          this.notification.show = true
          this.notification.text = this.$t('site_settings.delete_all_offers_success')
        }
      })
    
    },
    async deleteAllGoodsImages() {
      this.show_delete_all_goods_images_modal = false
      await this.$store.dispatch('deleteAllGoodsImages')
      .then(resp => {
        if(resp === 200) {
          this.notification.type = 'is-success'
          this.notification.show = true
          this.notification.text = this.$t('site_settings.delete_all_goods_images_success')
        }
      })

    },
    siteYMLUpdateCallback(yml) {
      this.loading = false
      if (yml === 'error') {
        this.notification.type = 'is-danger'
        this.notification.show = true
        this.notification.text = this.$t('site_forms.update_error_message')
      } else {
        this.notification.type = 'is-success'
        this.notification.show = true
        this.notification.text = this.$t('site_settings.update_yml_success')
      }
    },

    addNewTestSignificantParam() {
      if (this.new_test_significant_param.length > 0) {
        this.test_regular.significant_params.push(this.new_test_significant_param)
        this.new_test_significant_param = ''
      }
    },
    deleteTestSignificantParam(index) {
      this.test_regular.significant_params.splice(index, 1)
    },
    testOffersRegular() {
      this.test_regular_result = null
      let validate_regex_brakets = this.test_regular.regex.match(/\(.+?\)/g)
      if (
        this.test_regular.url.length === 0 ||
        this.test_regular.regex.length === 0 ||
        !validate_regex_brakets ||
        validate_regex_brakets.length === 0
      ) {
        if (!validate_regex_brakets || validate_regex_brakets.length === 0) {
          this.test_regular.regex_error = true
        }
        return
      }

      this.$store.dispatch('testOffersRegular', {
        vm: this,
        callback: this.testOffersRegularCallback
      })
    },
    testOffersRegularCallback(result) {
      if (result !== 'error') {
        this.test_regular_result = result
      } else {
        this.notification.type = 'is-danger'
        this.notification.show = true
        this.notification.text = this.$t('error_has_occurred')
      }
    },

    changeUserScript(value) {
      this.settings.lh_vars.user_script = value
    },
    changeFormsScript(value) {
      this.settings.lh_vars.forms_script = value
    },

    subscriptionDateFormat(date) {
      return date && new Date(date) ? moment(date).format('DD.MM.YYYY') : date
    },
    checkSiteSubscription() {
      if (typeof this.site_subscription.is_active !== 'boolean') {
        return false
      }

      if (!this.site_subscription.limits.mass_emails_count) {
        return false
      }

      if (!this.site_subscription.limits.autocast_emails_count) {
        return false
      }

      if (!this.site_subscription.renew_limits_at) {
        return false
      }

      if (!this.site_subscription.paid_till) {
        return false
      }

      if (typeof this.site_subscription.expiration_alert !== 'boolean') {
        return false
      }

      if (!this.site_subscription.expiration_alert_trigger) {
        return false
      }

      return true
    },
    async checkNeedUpdateSiteSubscription() {

      let subscription = this.$store.getters.site_subscription

      if (!subscription && this.createSubscribeFlag) {
        if (!this.checkSiteSubscription()) {
          return this.$message('Заполните все поля для создания подписки', 'danger')
        }

        this.loading_subscription = true
        await this.$store.dispatch('createSiteSubscription', {
          site_id: this.$route.params.site_id,
          subscription: this.site_subscription
          
        })

        const sub = this.$store.getters.site_subscription

        if (sub) {
          this.site_subscription.limits.mass_emails_count = sub.limits.mass_emails_count
          this.site_subscription.limits.autocast_emails_count = sub.limits.autocast_emails_count
          this.site_subscription.is_active = sub.is_active
          this.site_subscription.renew_limits_at = sub.renew_limits_at
          this.site_subscription.paid_till = new Date(sub.paid_till)
          this.site_subscription.expiration_alert = sub.expiration_alert
          this.site_subscription.expiration_alert_trigger = sub.expiration_alert_trigger

        }
        this.loading_subscription = false

      } else if (
        subscription.is_active !== this.site_subscription.is_active ||
        subscription.limits.mass_emails_count !== this.site_subscription.limits.mass_emails_count ||
        subscription.limits.autocast_emails_count !== this.site_subscription.limits.autocast_emails_count ||
        subscription.renew_limits_at !== this.site_subscription.renew_limits_at ||
        moment(new Date(subscription.paid_till)).format('X') !== moment(this.site_subscription.paid_till).format('X') ||
        subscription.expiration_alert !== this.site_subscription.expiration_alert ||
        subscription.expiration_alert_trigger !== this.site_subscription.expiration_alert_trigger ||
        subscription.alert_emails.join(', ') !== this.site_subscription.alert_emails
      ) {

        if(this.site_subscription.test_alert_emails.test(this.site_subscription.alert_emails) === false && this.site_subscription.expiration_alert) {
          return this.$message('Заполните адреса для оповещения корректно', 'danger')
        }
        this.loading_subscription = true
        this.$store.dispatch('updateSiteSubscription', {
          vue: this,
          callback: this.updateSiteSubscriptionCallback
        })
      } else {
        this.notification.text = this.$t('site_settings.no_changes')
        this.notification.type = 'is-info'
        this.notification.show = true
      }
    },
    updateSiteSubscriptionCallback(response) {
      if (response === 'error') {
        this.notification.type = 'is-danger'
        this.notification.show = true
        this.notification.text = this.$t('error_has_occurred')
      } else {
        this.site_subscription.limits.mass_emails_count = response.limits.mass_emails_count
        this.site_subscription.limits.autocast_emails_count = response.limits.autocast_emails_count
        this.site_subscription.is_active = response.is_active
        this.site_subscription.renew_limits_at = response.renew_limits_at
        this.site_subscription.paid_till = new Date(response.paid_till)
        this.site_subscription.expiration_alert = response.expiration_alert 
        this.site_subscription.expiration_alert_trigger = response.expiration_alert_trigger
        this.site_subscription.alert_emails = response.alert_emails.join(', ')

        this.notification.type = 'is-success'
        this.notification.show = true
        this.notification.text = this.$t('site_settings.subscription_updated_successfully')
      }
      this.loading_subscription = false
    },
    calculationHashSite() {
      this.$store.dispatch('calculationHashSite', {
        vue: this,
        callback: this.calculationHashSiteCallback
      })
    },
    calculationHashSiteCallback(response) {
      if (response === 'error') {
        this.notification.type = 'is-danger'
        this.notification.show = true
        this.notification.text = this.$t('site_settings.error_hash_site_calculated')
      } else {
        this.notification.type = 'is-success'
        this.notification.show = true
        this.notification.text = response.body.message
      }
    }
  }
}
</script>

<style lang="scss">
.site-settings-wrapper {
  position: relative;
  padding-bottom: 100px;

  .site-settings-content-wrapper {
    position: relative;

    .loading-overlay {
      width: 100%;
      min-height: 300px;
    }
    .site-setings-content {
      margin-top: 20px;
      border-radius: 4px;
      padding: 15px;
      border: 1px solid #dbdbdb;

      .select-input {
        width: 250px;
      }
      .language-wrapper {
        height: 80px;
      }
      .settings-title {
        margin-bottom: 25px;
      }
      .collect-approvement,
      .regex-cart-wrapper,
      .yml-update-block,
      .yml-title,
      .yml-significant-params-wrapper,
      .yml-url-wrapper,
      .yml-regex-wrapper,
      .yml-picture-dimensions-wrapper,
      .multiple-custom-values-input-wrapper
      .yml-parse-start-hour-wrapper {
        margin-bottom: 20px;
      }
      .is-active,
      .regex-phone-wrapper,
      .upload-subscriptions {
        margin-bottom: 15px;
      }
      .language-title,
      .domain-title,
      .regex-phone-title,
      .regex-cart-title,
      .yml-url-title,
      .yml-significant-params-title,
      .yml-item-title,
      .custom-input-title {
        margin-bottom: 5px;
      }
      .regex-phone-wrapper,
      .regex-cart-wrapper {
        width: 400px;
      }
      .yml-parse-start-hour-wrapper {
        width: 30%;
        margin-bottom: 20px;
      }
      .domain-name-wrapper {
        margin-bottom: 20px;

        input {
          width: 250px;
        }
      }
      .monaco_editor_container {
        margin-bottom: 30px;
      }

      .yml-loading-title {
        text-align: center;
      }
      .yml-loading {
        position: relative;
        min-height: 100px;
        margin-top: 15px;

        .loading-background {
          position: relative;
        }
      }
      .yml-errors-block {
        margin-top: 10px;
      }
      .yml-errors-wrapper {
        margin-top: 20px;
      }
      .upload-images {
        margin-bottom: 10px;
      }

      .multiple-custom-values-input-wrapper {
        width: 400px;

        .multiple-custom-values-wrapper {
          border: 1px solid #dbdbdb;
          border-radius: 4px;
          padding: 5px 10px;

          .new-multiple-custom-values {
            display: flex;
            align-items: center;
            position: relative;

            input {
              height: 30px;
              padding: 5px 10px;
              border: 0;
              width: calc(100% - 25px);
              font-size: 14px;
            }
            input:focus {
              outline: none;
            }
            img {
              cursor: pointer;
            }
          }
        }
      }
    }
    .yml-wrapper {
      display: flex;
      flex-wrap: nowrap;

      .total-offer-count-block {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        .total-offer-count-block-btn {
          margin-left: 24px;
        }
      }

      .yml-significant-params-wrapper {
        min-width: 450px;

        .significant-params-wrapper {
          border: 1px solid #dbdbdb;
          border-radius: 4px;
          padding: 5px 10px;

          .new-significant-param {
            display: flex;
            align-items: center;

            input {
              height: 30px;
              padding: 5px 10px;
              border: 0;
              width: calc(100% - 25px);
              font-size: 14px;
            }
            input:focus {
              outline: none;
            }
            img {
              cursor: pointer;
            }
          }
          .exists-significant-params {
            display: flex;
            flex-wrap: wrap;
            padding-top: 10px;
            max-height: 220px;
            overflow-y: auto;
            border-top: 1px solid #dbdbdb;

            .exists-significant-param {
              display: flex;
              align-items: center;
              margin-right: 20px;
              margin-bottom: 10px;
              border: 1px solid #dbdbdb;
              border-radius: 4px;
              padding: 3px 6px;

              img {
                margin-left: 7px;
                width: 15px;
                height: 15px;
              }
            }
          }
        }
      }
      .site-setings-content:first-child {
        min-width: calc(60% - 20px);
      }
      .site-setings-content:last-child {
        height: fit-content;
      }
      .right-yml-container {
        min-width: 40%;
        display: flex;
        flex-direction: column;
      }
      .test-regular-result {
        margin-top: 20px;

        .test-regular-result-title {
          margin-bottom: 15px;
        }
        .result-item:not(:last-child) {
          margin-bottom: 5px;
        }
        .result-item {
          .result-value {
            font-weight: bold;
          }
        }
      }
      .yml-picture-dimensions {
        display: flex;

        .picture-width {
          margin-right: 20px;
        }
      }
    }
    .yml-wrapper > div:first-child {
      margin-right: 20px;
    }
    .autocomplete-wrapper {
      position: absolute;
      border: 1px solid #dbdbdb;
      border-radius: 4px;
      padding: 5px 10px;
      background: #ffffff;
      width: 400px;
      z-index: 100;
      margin-top: 5px;
      max-height: 200px;
      overflow-y: auto;

      .autocomplete-result-wrapper {
        cursor: pointer;
      }
      .autocomplete-result-wrapper:hover {
        background-color: whitesmoke;
        color: #0a0a0a;
      }
    }

    .site-subscription-block {
      .collect-approvement {
        width: 350px;
      }

      .expiration-alert-toggle {
        width: auto;
      }

      .loading-overlay {
        position: relative;
      }
    }
  }
  .modal-content {
    width: 500px !important;

    .modal-card-body {
      position: relative;
      border-radius: 3px;
      height: 200px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      .custom-close-modal {
        position: absolute;
        top: 15px;
        right: 15px;
        cursor: pointer;
      }
      .modal-buttons {
        button:first-child {
          margin-right: 20px;
        }
      }
    }
  }
  .notification-wrapper {
    position: fixed;
    right: 30px;
    top: 30px;
    z-index: 100;
  }
}
</style>
